import { Button, DatePicker, DatePickerInput, IconButton, Modal as CarbonModal, Loading } from '@carbon/react';
import React, { useEffect, useState } from 'react';
import { TrashCan } from '@carbon/icons-react';

import { handleTableSubmitLayout, refreshTable } from '../utils/Datatable';
import { Modal } from '../utils/Modal';
import { PostResponseNotification, SelectUtil } from '../utils';
import { blockUser, unblockUser, getAccountData } from './accountManagerAPI';
import { PaginatedDatatable } from '../utils/PaginatedDatatable';

export const AccountManager = () => {
    const headers = [
        {
            header: 'ID', // Display on UI table header
            key: 'id', // id to link row with header
            format: (row) => {
                return JSON.stringify(row.userID);
            }, // format of how the column should display
        },
        {
            header: 'Email',
            key: 'email',
            format: (row) => {
                return row.email;
            },
        },
        {
            header: 'Status',
            key: 'status',
            format: (row) => {
                return row.status;
            },
        },
        {
            header: 'Account Type',
            key: 'accountType',
            format: (row) => {
                return row.accountType;
            },
        },
        {
            header: 'Expired Date',
            key: 'expiredDate',
            format: (row) => {
                return row.adminExpiry;
            },
        },
        {
            header: '',
            key: 'submit',
            format: (row) => {
                let btnProps = {
                    children: '',
                    onClick: '',
                    className: 'w-100',
                    kind: 'ghost',
                };

                if (row.status === 'registrationPending') {
                    btnProps.children = 'Register';
                    btnProps.onClick = () => {
                        handleClick(row, 'register');
                    };
                } else {
                    btnProps.children = 'Edit';
                    btnProps.onClick = () => {
                        handleClick(row, 'edit');
                    };
                }

                return <Button {...btnProps} />;
            },
        },
        {
            header: '',
            key: 'block',
            format: (row) => {
                let buttonProps = {
                    className: 'w-100',
                    kind: 'ghost',
                };
                if (row.status.includes('block')) {
                    buttonProps.children = 'Unblock';
                    buttonProps.onClick = () => {
                        handleOpenBlockModal(row, 'unblock');
                    };
                } else {
                    buttonProps.children = 'Block';
                    buttonProps.onClick = () => {
                        handleOpenBlockModal(row, 'block');
                    };
                }
                return <Button {...buttonProps} />;
            },
        },
        {
            header: '',
            key: 'delete',
            format: (row) => {
                return (
                    <IconButton
                        label="Delete"
                        kind="ghost"
                        children={<TrashCan />}
                        onClick={() => {
                            handleDeleteClick(row);
                        }}
                    />
                );
            },
        },
    ];
    // TABLE ROWS/DATA
    const [rows, setRows] = useState([]); // raw table data
    const [formattedRows, setFormattedRows] = useState([]); // table data formatted for UI look
    const [selectedRow, setSelectedRow] = useState({});
    const [datatableStatus, setDatatableStatus] = useState('loading');

    // FORM MODAL PROPS
    const [openFormModal, setOpenFormModal] = useState(false);
    const [submitType, setSubmitType] = useState('');
    const [formAdminExpiry, setFormAdminExpiry] = useState('');
    const [formAccountType, setFormAccountType] = useState({ value: 'user', invalid: false });
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [notificationData, setNotificationData] = useState({});

    const [blockIsLoading, setBlockIsLoading] = useState(false);
    const [blockModalUser, setBlockModalUser] = useState({ userId: null, username: '', action: 'block' });

    const handleSubmit = async () => {
        setDatatableStatus('loading');
        setButtonDisabled(true);
        let url = submitType === 'edit' ? '/api/account/editAccount' : '/api/account/approveAccount';
        let postData = {
            adminExpiry: formAdminExpiry,
            accountType: formAccountType.value,
            userID: selectedRow.userID,
        };
        try {
            const data = await handleTableSubmitLayout(url, postData);

            refreshTable(data, headers, setRows, setFormattedRows);
            setOpenFormModal(false);
            setDatatableStatus(!(data.length === 0));
        } catch (err) {
            setNotificationData(err.response);
        } finally {
            setButtonDisabled(false);
        }
    };

    /**
     *
     * @param {any} row
     * @param {'block' | 'unblock'} action
     */
    const handleOpenBlockModal = (row, action) => {
        if (!row.userID) {
            throw new Error('Could not find user');
        }
        setBlockModalUser({
            userId: row.userID,
            username: row.name,
            action,
        });
    };

    const handleCloseBlockModal = () => {
        setBlockModalUser({
            userId: null,
            username: '',
            action: blockModalUser.action,
        });
    };

    const handleClick = (row, type) => {
        setSelectedRow(row);
        setSubmitType(type);
        setFormAdminExpiry(row.adminExpiry);
        setFormAccountType((state) => {
            return { ...state, value: row.accountType || 'user' };
        });
        setOpenFormModal(true);
    };

    // DELETE MODAL PROPS
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const handleDeleteSubmit = async () => {
        setDatatableStatus('loading');
        setButtonDisabled(true);
        let url = '/api/account/deleteAccount';
        let postData = {
            userID: selectedRow.userID,
        };
        try {
            const data = await handleTableSubmitLayout(url, postData);

            refreshTable(data, headers, setRows, setFormattedRows);
            setOpenDeleteModal(false);
            setDatatableStatus(!(data.length === 0));
        } catch (error) {
            setNotificationData(error.response);
        } finally {
            setButtonDisabled(false);
        }
    };

    const handleDeleteClick = (row) => {
        setSelectedRow(row);
        setOpenDeleteModal(true);
    };

    useEffect(() => {
        (async () => {
            try {
                await global.GetCSRFToken();
                const data = await getAccountData();
                refreshTable(data, headers, setRows, setFormattedRows);
                setDatatableStatus(!(data.length === 0));
            } catch (error) {
                console.error(error);
                setDatatableStatus(false);
            }
        })();
    }, []);

    return (
        <>
            <Modal
                modal={{
                    open: openFormModal,
                    onClose: () => {
                        setOpenFormModal(false);
                    },
                    'data-testid': 'manager-edit-modal',
                }}
                header={{
                    title: submitType === 'edit' ? 'Edit Account' : 'Register Account',
                }}
                footer={{
                    hasFooter: true,
                    footerProps: {
                        onRequestSubmit: handleSubmit,
                        primaryButtonDisabled: buttonDisabled,
                    },
                }}
                children={
                    <>
                        <SelectUtil
                            select={{
                                hideLabel: false,
                                labelText: 'ACCOUNT TYPE',
                                value: formAccountType.value || 'user',
                                className: 'mb-3',
                            }}
                            options={{ user: 'user', moderator: 'moderator', admin: 'admin' }}
                            setState={setFormAccountType}
                        />
                        <AccountDatePicker
                            formAdminExpiry={formAdminExpiry}
                            setFormAdminExpiry={setFormAdminExpiry}
                            formType={formAccountType.value}
                        />
                        <PostResponseNotification response={notificationData} />
                    </>
                }
            />
            <Modal
                modal={{
                    open: openDeleteModal,
                    onClose: () => {
                        setOpenDeleteModal(false);
                    },
                    'data-testid': 'manager-delete-modal',
                }}
                header={{
                    title: 'Delete Account',
                }}
                footer={{
                    hasFooter: true,
                    footerProps: {
                        onRequestSubmit: handleDeleteSubmit,
                        primaryButtonDisabled: buttonDisabled,
                    },
                }}
                children={
                    <>
                        <div>{`Are you sure you want to delete ${selectedRow.email}.`}</div>
                        <PostResponseNotification response={notificationData} />
                    </>
                }
            />
            <PaginatedDatatable
                headers={headers}
                rows={formattedRows}
                tableTitle="Account Manager"
                datatableStatus={datatableStatus}
                enablePagination
                enableSearch
            />
            <BlockUserModal
                isLoading={blockIsLoading}
                isOpen={!!blockModalUser.userId}
                onClose={handleCloseBlockModal}
                onSubmit={async () => {
                    setBlockIsLoading(true);
                    if (blockModalUser.action === 'block' && blockModalUser.userId) {
                        await blockUser({ userId: blockModalUser.userId });
                    } else if (blockModalUser.action === 'unblock' && blockModalUser.userId) {
                        await unblockUser({ userId: blockModalUser.userId });
                    }
                    setBlockIsLoading(false);
                    handleCloseBlockModal();
                    const data = await getAccountData();
                    refreshTable(data, headers, setRows, setFormattedRows);
                    setDatatableStatus(!(data.length === 0));
                }}
                username={blockModalUser.username}
                action={blockModalUser.action}
                notificationData={{}}
            />
        </>
    );
};

const AccountDatePicker = ({ formAdminExpiry, setFormAdminExpiry, formType }) => (
    <DatePicker
        datePickerType="single"
        onChange={(fullDate, val) => {
            setFormAdminExpiry(val);
        }}
        dateFormat="m/d/Y"
        minDate={new Date().toString()}
        maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toString()}
        value={formAdminExpiry || ''}
    >
        <DatePickerInput
            placeholder="MM/DD/YYYY"
            id={`datepicker${Math.random().toString()}`}
            labelText="ADMIN EXPIRY"
            disabled={formType !== 'admin'}
            // disabled={formAccountType.value !== 'admin'}
        />
    </DatePicker>
);

const BlockUserModal = ({ isLoading, onClose, isOpen, username, action, onSubmit, notificationData = {} }) => {
    return (
        <CarbonModal
            open={isOpen}
            modalHeading={action === 'block' ? 'Block user' : 'Unblock User'}
            primaryButtonText={action === 'block' ? 'Block' : 'Unblock'}
            secondaryButtonText="Cancel"
            onRequestClose={onClose}
            onRequestSubmit={onSubmit}
            size="sm"
            disabled={isLoading}
        >
            <div>
                {isLoading ? <Loading /> : null}
                <h4>
                    Are you sure you want to {action} {username}?
                </h4>
                <PostResponseNotification response={notificationData} />
            </div>
        </CarbonModal>
    );
};
