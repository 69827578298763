import React, { useEffect, useState } from 'react';
import { EmailField, PasswordField, ButtonUtil, LinkUtil, PostResponseNotification, LoadingUtil } from '../../utils';
import './LoginForm.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { Checkbox } from '@carbon/react';
import axios from 'axios';
import { InputValidator } from '../../helpers';
import { useSetAtom } from 'jotai';
import { isLoggedInAtom } from '../../utils/PrivateRoute';

export const LoginForm = () => {
    const [email, setEmail] = useState({ value: '' });
    const [password, setPassword] = useState({ value: '' });
    const [notificationData, setNotificationData] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);

    const [buttonDisabled, setButtonDisabled] = useState(true);
    const location = useLocation();
    const setIsLoggedIn = useSetAtom(isLoggedInAtom);

    useEffect(() => {
        (async () => {
            await global.GetCSRFToken();
        })();
    }, []);

    useEffect(() => {
        const email = window.localStorage.getItem('email') ?? '';
        const conditions = InputValidator({
            type: 'emailBasic',
            value: email,
        });
        if (email) {
            setEmail({ value: email, invalid: !conditions.allConditionsMet });
            setRememberMe(true);
        }
    }, []);

    useEffect(() => {
        if (email.invalid === false && email.value.length > 0 && password.value.length > 0) {
            setButtonDisabled(false);
        }

        return () => {
            setButtonDisabled(true);
        };
    }, [email.value, password.value, email.invalid]);

    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonDisabled(true);
        try {
            const res = await axios.post(
                '/api/login',
                {
                    email: email.value,
                    password: password.value,
                    redirectURL: location.state,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                    credentials: 'include',
                }
            );

            if (res.data.status === 'success' && res.data.tfa) {
                setIsLoggedIn(null);
                navigate('/authentication-options');
            } else if (res.data.status === 'success') {
                setIsLoggedIn(null);
                if (rememberMe) {
                    window.localStorage.setItem('email', email.value);
                } else {
                    window.localStorage.removeItem('email');
                }
                if (location.state) {
                    navigate(location.state);
                } else {
                    navigate('/manpower-wizard');
                }
            }

            setNotificationData(res);
        } catch (err) {
            setNotificationData(err.response);
            console.log(err);
        } finally {
            setTimeout(() => {
                setButtonDisabled(false);
            }, '1000');
        }
    };

    return (
        <div className="login-content">
            <div className="email-content mt-3 mb-3">
                <EmailField
                    textInput={{
                        ...email,
                        onChange: () => {
                            setFormSubmitted();
                        },
                    }}
                    type="emailDomain"
                    validation
                    setState={setEmail}
                />
            </div>
            <div className="password-content">
                <PasswordField
                    passwordInput={{
                        ...password,
                        onChange: () => {
                            setFormSubmitted();
                        },
                        onKeyUp: (e) => {
                            if (e.key === 'Enter') {
                                handleSubmit(e);
                            }
                        },
                        style: {},
                        labelText: <>Password</>,
                    }}
                    type="password"
                    setState={setPassword}
                    validation={false}
                />
            </div>
            <div className="d-flex justify-content-end align-items-center mt-3">
                <Checkbox
                    labelText="Remember me"
                    id="rememberMe"
                    className="ms-4"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                />
                <LinkUtil
                    pathKey="forgotPassword"
                    link={{
                        children: 'Forgot Password?',
                        className: 'small',
                    }}
                />
            </div>
            <p className="ms-4 mt-4 small">
                <span className="terms">By signing in you accept the</span>
                <LinkUtil
                    pathKey="tos"
                    link={{
                        children: ' Terms of Use and acknowledge the Privacy Policy and Cookie Policy.',
                    }}
                />
            </p>
            <div className="d-flex justify-content-end mt-4">
                <ButtonUtil
                    button={{
                        children: (
                            <div className="button-content">
                                <div className="text-content">Sign In</div>{' '}
                                {formSubmitted && (
                                    <div className="loading-content">
                                        <LoadingUtil
                                            inlineLoading={{
                                                className: 'loading-util',
                                            }}
                                            type="inline"
                                        />
                                    </div>
                                )}
                            </div>
                        ),
                        onClick: handleSubmit,
                        disabled: buttonDisabled || formSubmitted === false,
                        type: 'submit',
                        role: 'submit',
                    }}
                />
            </div>
            <PostResponseNotification response={notificationData} />
        </div>
    );
};
