import React from 'react';
import { ComboBox } from '@carbon/react';
import { useAtom } from 'jotai';
import { mxEquationsAtom } from '../../../../../../hooks/mxAtoms';
import { comboBoxShouldFilterItem } from '../../../../../../helpers';

export const MxDropdown = ({
    labelText,
    selectedItemAtom,
    items,
    disabled,
}) => {
    const [value, setValue] = useAtom(selectedItemAtom);
    return (
        <div className="MxDropdown" data-testid="mx-dropdown">
            <ComboBox
                titleText={labelText}
                label={labelText}
                id={`MxDropdown_${labelText}`}
                items={items}
                selectedItem={value}
                onChange={(selectedItem) => setValue(selectedItem.selectedItem)}
                disabled={disabled}
                shouldFilterItem={comboBoxShouldFilterItem}
            />
        </div>
    );
};
