import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useAtom } from 'jotai';
import { Button, NumberInput, ComboBox, TextInput } from '@carbon/react';
import { Add, TrashCan } from '@carbon/react/icons';

import { gsFiltersAtom } from '../../../steps/generalSettings/GeneralSettingsAtoms';
import { useScrollIntoViewOnMount } from '../../../../../hooks';
import { useUpdateModelState } from './useUpdateModelState';
import { comboBoxShouldFilterItem } from '../../../../../helpers';

export const UpdateModel = ({
    model = { parent: {}, children: [] },
    models,
    action,
    submitting,
    onCloseUpdateModel,
    onAddUpdatedModel,
}) => {
    const [takenError, setTakenError] = useState(false);
    const [gsFilters] = useAtom(gsFiltersAtom);
    const scrollRef = useScrollIntoViewOnMount();
    const addRowRef = useRef();
    const { state, isModelStateValid, handleModelChange, checkModelNameCollision, formatModelsForSubmission } =
        useUpdateModelState({ ...model, parent: { ...model.parent } });

    const applyModelChangesButtonText = {
        edit: 'Update Existing Model',
        copy: 'Save as New Model',
        add: 'Create New Model',
    };

    const handleApplyModelChanges = () => {
        if (checkModelNameCollision(models, action)) {
            setTakenError(true);
        } else {
            const result = formatModelsForSubmission(action);
            onAddUpdatedModel(result);
        }
    };

    return (
        <span data-testid="update-model">
            <div className="row form-inputs-top" data-testid="update-model-parent-row">
                {action === 'edit' ? <h5 className="mb-2">Edit Model</h5> : <h5 className="mb-2">Add Model</h5>}
                <div className="col-md-2" ref={scrollRef}>
                    <ComboBox
                        id="MDSDropdown"
                        titleText="MDS"
                        label=""
                        placeholder="Please Select..."
                        items={[gsFilters.MDS]}
                        selectedItem={state.MDS}
                        shouldFilterItem={comboBoxShouldFilterItem}
                        onChange={({ selectedItem }) => handleModelChange({ MDS: selectedItem || '' })}
                        disabled={typeof model.parent.MDS === 'string' ? model.parent.MDS.length > 0 : false}
                    />
                </div>
                <div className="col-md-2">
                    <ComboBox
                        id="CMDDropdown"
                        titleText="CMD"
                        label=""
                        placeholder="Please Select..."
                        items={[gsFilters.CMD]}
                        selectedItem={state.CMD}
                        shouldFilterItem={comboBoxShouldFilterItem}
                        onChange={({ selectedItem }) => handleModelChange({ CMD: selectedItem || '' })}
                        disabled={typeof model.parent.CMD === 'string' ? model.parent.CMD.length > 0 : false}
                    />
                </div>
                <div className="col-md-3">
                    <TextInput
                        id="update-model-model-name"
                        labelText="Model Name"
                        value={state.MODEL_NAME || ''}
                        onChange={(e) => {
                            handleModelChange({ MODEL_NAME: e.target.value });
                            setTakenError(false);
                        }}
                        data-testid="update-model-model-name"
                        invalidText="Model name must be unique"
                        invalid={takenError}
                    />
                </div>
                <div className="col-md-3">
                    <TextInput
                        id="update-model-source"
                        labelText="Source"
                        value={state.SOURCE || ''}
                        onChange={(e) => handleModelChange({ SOURCE: e.target.value })}
                    />
                </div>
                <div className="col-md-2">
                    <TextInput
                        id="update-model-poc"
                        labelText="POC"
                        value={state.POC || ''}
                        onChange={(e) => handleModelChange({ POC: e.target.value })}
                    />
                </div>
            </div>
            {state.composition.map((crew, idx) => (
                <div
                    key={`crew_composition_${crew.id}`}
                    data-testid="update-model-child-row"
                    className="row form-inputs-bottom"
                >
                    <div className="col-md-2">
                        <TextInput
                            id="update-model-notes"
                            labelText="Notes"
                            value={crew.NOTES || ''}
                            data-testid="update-model-notes"
                            onChange={(e) => handleModelChange({ NOTES: e.target.value }, 'update_row', idx)}
                        />
                    </div>
                    <div className="col-md-2">
                        <TextInput
                            id={`${crew.id}_update-model-crew-position`}
                            labelText="Aircrew Position"
                            value={crew.CREW_POSITION || ''}
                            data-testid="update-model-crew-position"
                            onChange={(e) => handleModelChange({ CREW_POSITION: e.target.value }, 'update_row', idx)}
                        />
                    </div>
                    <div className="col-md-2">
                        <TextInput
                            id={`${crew.id}_update-model-afsc`}
                            labelText="AFSC"
                            value={crew.AFSC || ''}
                            data-testid="update-model-afsc"
                            onChange={(e) => handleModelChange({ AFSC: e.target.value }, 'update_row', idx)}
                        />
                    </div>
                    <div className="col-md-2" data-testid="update-model-crew-ratio">
                        <NumberInput
                            className="num-input-p0"
                            id={`${crew.id}_num-input-aircrew-ratio`}
                            min={0}
                            max={999}
                            value={crew.CREW_RATIO || 0}
                            step={0.1}
                            label="Aircrew Ratio"
                            invalidText="Number is not valid"
                            onChange={(e, { value, direction }) => {
                                let num = value;
                                const valueInState = crew.CREW_RATIO;
                                if (e.type === 'click') {
                                    num = direction === 'up' ? valueInState + 0.1 : valueInState - 0.1;
                                }
                                handleModelChange(
                                    {
                                        CREW_RATIO: parseFloat(parseFloat(num).toFixed(2)),
                                    },
                                    'update_row',
                                    idx
                                );
                            }}
                        />
                    </div>
                    <div className="col-md-2" data-testid="update-model-crew-enl">
                        <NumberInput
                            id={`${crew.id}_num-input-aircrew-complement-enl`}
                            min={0}
                            max={999}
                            value={crew.CREW_COMP_ENL || 0}
                            step={1}
                            label="Crew Complement ENL"
                            invalidText="Number is not valid"
                            onChange={(_e, { value }) =>
                                handleModelChange({ CREW_COMP_ENL: parseFloat(value) }, 'update_row', idx)
                            }
                        />
                    </div>
                    <div className="col-md-2 d-flex align-items-end" data-testid="update-model-crew-off">
                        <NumberInput
                            id={`${crew.id}_num-input-aircrew-complement-off`}
                            min={0}
                            max={999}
                            value={crew.CREW_COMP_OFF || 0}
                            step={1}
                            label="Crew Complement OFF"
                            invalidText="Number is not valid"
                            onChange={(_e, { value }) =>
                                handleModelChange({ CREW_COMP_OFF: parseFloat(value) }, 'update_row', idx)
                            }
                        />
                        <div className="ms-2">
                            <Button
                                renderIcon={TrashCan}
                                kind="tertiary"
                                size="md"
                                iconDescription="Trash"
                                hasIconOnly
                                onClick={() => handleModelChange(crew.id, 'delete_row')}
                                data-testid="update-model-delete-row-button"
                                aria-label="delete"
                                disabled={state.composition.length < 2}
                            />
                        </div>
                    </div>
                </div>
            ))}
            <div className="d-flex mt-2 ms-auto">
                <Button
                    className="me-auto"
                    kind="tertiary"
                    size="md"
                    ref={addRowRef}
                    renderIcon={Add}
                    iconDescription="plus sign"
                    onClick={() => {
                        handleModelChange(null, 'add_row');
                        try {
                            requestAnimationFrame(() => {
                                addRowRef.current?.scrollIntoView?.({
                                    behavior: 'smooth',
                                });
                            });
                        } catch (error) {
                            console.error(error);
                        }
                    }}
                    data-testid="update-model-add-row-button"
                >
                    Add Row
                </Button>
                <Button className="me-2" kind="tertiary" size="md" onClick={onCloseUpdateModel}>
                    Cancel
                </Button>
                <Button
                    kind="primary"
                    size="md"
                    onClick={handleApplyModelChanges}
                    disabled={!isModelStateValid || submitting}
                    data-testid="update-model-apply-changes"
                >
                    {applyModelChangesButtonText[action]}
                </Button>
            </div>
        </span>
    );
};

UpdateModel.propTypes = {
    model: PropTypes.shape({ parent: PropTypes.object, children: PropTypes.arrayOf(PropTypes.object) }),
    models: PropTypes.arrayOf(PropTypes.object),
    action: PropTypes.oneOf(['edit', 'add', 'copy']),
    submitting: PropTypes.bool,
    onCloseUpdateModel: PropTypes.func.isRequired,
    onAddUpdatedModel: PropTypes.func.isRequired,
};
