import React from 'react';
import { InlineNotification } from '@carbon/react';

/**
 * const [notificationData, setNotificationData] = useState({});
 *
 * setNotificationData(res);
 * setNotificationData(err.response);
 *
 * <PostResponseNotification response = {notificationData} />
 */
export const PostResponseNotification = ({ response = {}, inlineNotification = {} }) => {
    let errorMessage;

    if (response.data && response.status === 200) {
        switch (response.data.status) {
            case 'error':
                errorMessage = {
                    title: 'Request failed',
                    subtitle: response.data.errorMessage,
                };
                break;
            case 'unauthorized':
                errorMessage = {
                    title: 'Unauthorized',
                    subtitle: 'You do not have sufficient permissions to access this content.',
                };
                break;
            case 'unregistered':
                errorMessage = {
                    title: 'Account not found',
                    subtitle: 'Enter your email correctly or register this email with a new account.',
                };
                break;
            case 'blocked':
                errorMessage = {
                    title: 'Account temporarily suspended',
                    subtitle: 'Contact a project admin to have your account unblocked.',
                };
                break;
            case 'blockedReactivate':
                errorMessage = {
                    title: 'Account temporarily suspended',
                    subtitle: 'You may reactivate your account by following the link sent to your email.',
                };
                break;
            case 'inactive':
                errorMessage = {
                    title: 'Account is not active',
                    subtitle: 'Contact a project admin to reactivate your account.',
                };
                break;
            case 'wrongPassword':
                errorMessage = {
                    title: 'Incorrect login credentials',
                    subtitle: 'Re-enter your password and try again.',
                };
                break;
            case 'loginWarning1':
                errorMessage = {
                    title: 'Incorrect login credentials',
                    subtitle:
                        response.data.remainingLoginAttempts === 1
                            ? `You have 1 remaining login attempt.`
                            : `You have ${response.data.remainingLoginAttempts} remaining login attempts.`,
                };
                break;
            case 'loginWarning2':
                errorMessage = {
                    title: 'Incorrect login credentials',
                    subtitle:
                        response.data.remainingLoginAttempts == 1
                            ? `You have 1 remaining login attempt.`
                            : `You have ${response.data.remainingLoginAttempts} remaining login attempts.`,
                };
                break;
            case 'alreadyExists':
                errorMessage = {
                    title: 'Account already exists',
                    subtitle: 'Sign in with this email or reset your password if you forgot it.',
                };
                break;
            case 'invalid':
                errorMessage = {
                    title: 'Invalid password',
                    subtitle: 'Retry enterting your current password.',
                };
                break;
            case 'invalidToken':
                errorMessage = {
                    title: 'Invalid token',
                    subtitle: 'Retry entering the token provided in your email.',
                };
                break;
            case 'registrationPending':
                errorMessage = {
                    title: 'Registration pending approval',
                    subtitle: 'Your account is currently pending approval from an administrator.',
                };
                break;
            case 'emailVerificationPending':
                errorMessage = {
                    title: 'Pending email verification',
                    subtitle: 'Follow the link sent to your email to proceed with registration.',
                };
                break;
            case 'resetPasswordEmailSent':
                errorMessage = {
                    title: 'Password reset accepted',
                    kind: 'success',
                    subtitle: 'Check your email for instructions to set a new password.',
                };
                break;
            case 'currentPasswordSuccess':
            case 'tokenSuccess':
                errorMessage = {
                    title: 'Password has been reset',
                    kind: 'success',
                };
                break;
            case 'authFail':
                errorMessage = {
                    title: 'Authentication failed.',
                };
                break;
            case 'invalidRecoveryCode':
                errorMessage = {
                    title: 'Invalid recovery code',
                    subtitle: 'The provided recovery code was not found.',
                };
                break;
            default:
                errorMessage = {
                    title: 'Your data has been received and is being processed.',
                    kind: 'info',
                };
        }
    }
    //error
    else if (response.data) {
        switch (response.status) {
            case 403:
                errorMessage = {
                    title: 'Invalid CSRF Token',
                    subtitle: 'Please resubmit your request.',
                };
                break;
            default:
                errorMessage = {
                    title: 'Error',
                    subtitle: 'Your request could not be processed.',
                };
        }
    }

    const notificationProps = {
        // lowContrast: true,
        hideCloseButton: true,
        className: 'my-3',
        kind: 'error',
        ...errorMessage,
        ...inlineNotification, // passing in title and subtitle overrides above
    };

    const notificationElement =
        Object.keys(response).length === 0 ? <></> : <InlineNotification {...notificationProps} />;

    return notificationElement;
};
